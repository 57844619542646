<template>
  <div class="page-wrap text-center">
    <div class="session-form-hold">
      <base-card :loading="loading">
        <v-card-text>
          <template v-if="loading">
            <v-alert border="left" class="text-left mb-0" text type="info">
              <strong>Atenção!</strong>
              Aguarde enquanto conectamos sua conta do Mercado Pago
            </v-alert>
          </template>
          <template v-else>
            <v-alert
              v-if="error"
              border="left"
              class="text-left"
              text
              type="error"
            >
              {{ error }}
            </v-alert>
            <v-alert v-else border="left" class="text-left" text type="success">
              Contas conectadas com sucesso!
            </v-alert>
            <v-btn color="primary" block :to="{ name: 'admin.profile' }">
              Voltar ao app
            </v-btn>
          </template>
        </v-card-text>
      </base-card>
    </div>
  </div>
</template>

<script>
import { mercadopagoConnect } from "@/services/api";

export default {
  data: () => ({
    loading: true,
    error: false,
  }),
  methods: {
    async connect(data) {
      try {
        this.loading = true;
        this.error = false;
        await mercadopagoConnect(data);
      } catch (e) {
        this.error =
          e.message ||
          "Não foi possível conectar as contas, por favor tente novamente";
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    let data = {
      code: this.$route.query.code,
      state: this.$route.query.state,
    };
    if (data.code && data.state) {
      this.connect(data);
    } else {
      this.loading = false;
      this.error = "Erro ao conectar conta";
    }
  },
};
</script>
<style lang="scss" scoped>
.page-wrap {
  background-color: #B01C3F !important;
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
</style>
